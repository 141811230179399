.App {
  font-family: MyFont;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;
  font-size: 25px;
  color: black;
}

.App-logo-no-anim {
  height: 120px;
}

.backgroundImage {
  height: 150px;
  width: 100%;
  top: 0;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

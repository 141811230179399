.App-footer {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 50px;
  background-color: #161616;
  display: flex;
  justify-content: space-evenly;
}

.Items {
  width: 750px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer-text {
  font-size: 23px;
  color: #f2a900;
}

.footerItems {
  font-size: 21px;
}

@media only screen and (max-width: 768px) {
  .App-footer {
    height: 50px;
  }
  .footer-text {
    font-size: 12px;
  }

  .footerItems {
    font-size: 10px;
  }
}
